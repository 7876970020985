<template>
    <v-card style="background-color: white" elevation="3">
        <v-alert
            v-if="alert.message"
            class="mb-0"
            :type="alert.type"
            dismissible
            dense
            outlined
            text
            transition="scale-transition"
            @input="dismissAlert">
            {{ alert.message }}
        </v-alert>
    </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            alert: s => s.alert,
        }),
    },

    methods: {
        dismissAlert() {
            this.$store.dispatch("alert/clear");
        },
    },
};
</script>
